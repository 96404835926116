import { Route } from './route';
import { Price } from '@model/common/currency';

export enum FlightType {
  DIRECT = 'Direct',
  INDIRECT = 'Indirect'
}

export enum Source {
  MULTI_COM = 'Multicom',
  AMADEUS = 'Amadeus',
  DEAL_FINDER = 'dealFinder'
}

export interface Flight {
  token: string;
  price: Price;
  total?: Price;
  outbound: Array<Route>;
  return: Array<Route>;
  includes: Array<string>;
  travelTime: TravelTime;
  value: number;
  source: Source;
  isOneWay?: boolean;
}

export interface TravelTime {
  outbound: number;
  inbound: number;
  total: number;
}
