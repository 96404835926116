export enum BookingEndpoints {
  BASKET = '/basket',
  EXTRAS = '/extras',
  ERRATAS = '/erratas',
  PRE_PAY = '/pre-pay',
  PROMO_CODE = '/promo-code',
  PACKAGES = '/package',
  FLIGHTS = '/flights',
  ROOMS = '/rooms',
  FLIGHT = '/flight',
  LUGGAGE = '/luggage',
  OPTION = '/option',
  OPTIONS = '/options',
  TRANSFER = '/transfer',
  GUESTS = '/guests',
  SEAT_MAPS = '/seat-maps',
  SUPPLEMENTS = '/supplements',
  SPECIAL_REQUESTS = '/special-requests',
  UPDATE = '/update'
}
