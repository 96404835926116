// Full list of formats available from
// https://momentjs.com/docs/#/displaying/format/
export const ISO_8601_DATE_FORMAT: string = 'YYYY-MM-DD';
export const YEAR_MONTH_FORMAT: string = 'YYYY-MM';
export const MONTH_YEAR_FORMAT: string = 'MMM YY'; // Aug 19
export const LONG_MONTH_YEAR_FORMAT: string = 'MMMM YYYY'; // August 2019
export const DAY_LONG_MONTH_FORMAT: string = 'DD MMMM'; // 31 August
export const LONG_DAY_MONTH_YEAR_FORMAT: string = 'DD MMMM YYYY'; // 31 August 2019
export const DOW_DAY_MONTH_FORMAT: string = 'ddd, Do MMM'; // Thu, 5th Aug
export const DAY_MONTH_YEAR_FORMAT: string = 'DD/MM/YY'; // 14/08/19
export const DATE_PICKER_FORMAT: string = 'DD MMM YY'; // 5 Aug 19
export const DOW_DAY_MONTH_YEAR_FORMAT: string = 'ddd Do MMM YYYY'; // Thu 5th Aug 2019
export const DAY_MONTH_YEAR_FORMAT_LONG: string = 'Do MMM YYYY'; // 5th Aug 2019
export const TIME_FORMAT_SHORT: string = 'HH:mm'; // '23:59'
export const TIME_FORMAT_LONG: string = 'hh:mma'; // '03:00am'
export const DAY_MONTH_FORMAT_SHORT: string = 'Do MMM'; // 11th Jan
export const MONTH_FORMAT: string = 'MMMM'; // January
export const MONTH_FORMAT_SHORT: string = 'MMM'; // Jan
export const YEAR_FORMAT: string = 'YYYY';
export const DAY_NUMBER: string = 'DD';
export const MONTH_NUMBER: string = 'M'; // 1..12 (Jan is 1)
export const MONTH_NUMBER_LONG: string = 'MM'; // 01..12 (Jan is 01)
export const YEAR_NUMBER: string = 'YYYY'; // 2021
export const FULL_YEAR_FORMAT: string = 'DD MMM YYYY'; // 21 Feb 2025
