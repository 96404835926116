import { Price } from '@model/common';

export enum TravelDirection {
  OUTBOUND = 'Outbound',
  RETURN = 'Return'
}

export enum GuestType {
  ADULT = 'Adult',
  CHILD = 'Child'
}

export enum SeatMapSeatAttributes {
  AISLE = 'AISLE',
  EXIT_ROW = 'EXIT_ROW',
  EXTRA_LEG_ROOM_SEATS = 'EXTRA LEG ROOM SEATS',
  RESTRICTED = 'RESTRICTED',
  STANDARD_SEATS = 'STANDARD SEATS',
  UNAVAILABLE = 'UNAVAILABLE'
}

export interface SeatGuest {
  type: GuestType;
  guestId: number;
  rowNumber: number | null;
  seatRef: string | null;
  price: Price | null;
}

export interface PassengerSelection {
  direction: TravelDirection;
  guests: Array<SeatGuest>;
}

export interface SeatMapSeat {
  token: string;
  rowNumber: number;
  seatRef: string;
  price: Price | null;
  available: boolean;
  attributes: Array<SeatMapSeatAttributes>;
  guestId: number | null;
}

export interface SeatMapRow {
  seats: Array<SeatMapSeat>;
}

export interface SeatMap {
  direction: TravelDirection;
  token: string;
  rows: Array<SeatMapRow>;
}

export interface SeatMapsResponse {
  passengerSelection: Array<PassengerSelection>;
  seatMaps: Array<SeatMap>;
}

export interface PutSeatMapsParams {
  searchToken: string;
  guestMaps: Array<GuestMap>;
}

export interface GuestMap {
  seatMapToken: string;
  seatToken: string | null;
  guestId: number;
  guestType: string;
}

export interface UIGuestMap extends GuestMap {
  seatRef: string | null;
  originalSeatToken: string | null;
  price: Price | null;
}
