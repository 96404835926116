import { AsyncData } from '@model/common';
import { put, putResolve, takeLatest } from 'redux-saga/effects';
import { BookingApi, PutSeatMapsParams } from '@model/iceberg/service/booking';
import { SeatMapsResponse } from '@model/iceberg/service';
import { DealFinderResultsActions } from '@state/deal-finder-results/dealFinderResultsOperations';
import { fetchBasket } from '@state/basket';
import { getErrorCode } from '@util/error';

export enum SeatMapsActions {
  FETCH_SEATMAPS = '@SEATMAPS/FETCH_SEATMAPS',
  CLEAR_SEATMAPS = '@SEATMAPS/CLEAR_SEATMAPS',
  RECEIVE_SEATMAPS_SUCCESS = '@SEATMAPS/RECEIVE_SEATMAPS_SUCCESS',
  RECEIVE_SEATMAPS_FAILURE = '@SEATMAPS/RECEIVE_SEATMAPS_FAILURE',
  PUT_SEATMAPS = '@SEATMAPS/PUT_SEATMAPS'
}

export const INITIAL_SEATMAPS_STATE: AsyncData<SeatMapsResponse | null> = {
  data: null,
  loading: false,
  error: null
};

export const fetchSeatMaps = (searchToken: string) => ({ type: SeatMapsActions.FETCH_SEATMAPS, searchToken });

export const clearSeatMaps = () => ({ type: SeatMapsActions.CLEAR_SEATMAPS });

export const receiveSeatMapsSuccess = (data: SeatMapsResponse) => ({
  type: SeatMapsActions.RECEIVE_SEATMAPS_SUCCESS,
  data
});

export const receiveSeatMapsFailure = (error: string) => ({
  type: SeatMapsActions.RECEIVE_SEATMAPS_FAILURE,
  error
});

export function* onFetchSeatMaps() {
  yield takeLatest(SeatMapsActions.FETCH_SEATMAPS, performFetchSeatMaps);
}

export function* performFetchSeatMaps({ searchToken }: any) {
  const bookingApi: BookingApi = new BookingApi();
  try {
    const response: SeatMapsResponse = yield bookingApi.fetchSeatMaps(searchToken);
    yield putResolve(receiveSeatMapsSuccess(response));
  } catch (e: any) {
    yield putResolve(receiveSeatMapsFailure(getErrorCode(e)));
  }
}

export const putSeatMaps = (payload: PutSeatMapsParams) => ({ type: SeatMapsActions.PUT_SEATMAPS, payload });

export function* onPutSeatMaps() {
  yield takeLatest(SeatMapsActions.PUT_SEATMAPS, performPutSeatMaps);
}

export function* performPutSeatMaps({ payload }: any) {
  const bookingApi: BookingApi = new BookingApi();

  try {
    const { searchToken } = payload;
    yield bookingApi.putSeatMaps(payload);
    yield put(fetchSeatMaps(searchToken));
    yield putResolve(fetchBasket(searchToken));
  } catch (e: any) {
    yield putResolve(receiveSeatMapsFailure(getErrorCode(e)));
  }
}

export const seatMapsReducer: any = (state: any = INITIAL_SEATMAPS_STATE, { type, data, error }: any) => {
  switch (type) {
    case SeatMapsActions.RECEIVE_SEATMAPS_SUCCESS:
      return { ...state, data, error: null, loading: false };
    case SeatMapsActions.RECEIVE_SEATMAPS_FAILURE:
      return { ...state, data: INITIAL_SEATMAPS_STATE.data, error, loading: false };
    case SeatMapsActions.FETCH_SEATMAPS:
    case SeatMapsActions.PUT_SEATMAPS:
      return { ...state, loading: true };
    case DealFinderResultsActions.CLEAR_RESULTS:
    case SeatMapsActions.CLEAR_SEATMAPS:
      return INITIAL_SEATMAPS_STATE;
    default:
      return state;
  }
};
