import { combinedAppReducers, CombinedAppReducersType } from '@state/app/combinedAppReducers';
import { basketReducer } from '@state/basket';
import { filtersReducer } from '@state/filters/filterOperations';
import { combinedSearchResultsReducers } from '@state/search-results/combinedSearchResultsReducers';
import { combineReducers, Reducer } from 'redux';
import { createMigrate, MigrationManifest, persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';
import { cmsReducer } from '../cms/cmsOperations';
import { navigationReducer } from '../navigation/navigationOperations';
import { combinedSearchReducers } from '@state/search/combinedSearchReducers';
import { guestsReducer } from '@state/guests/guestOperations';
import { recentlyViewedReducer } from '@state/recently-viewed';
import { extrasReducer } from '@state/extras';
import { extrasOptionsReducer } from '@state/extras-options';
import { seatMapsReducer } from '@state/seat-maps';
import { flightsSortReducer } from '@state/flights-sort';
import { componentsDataReducer } from '@state/components';
import { promoCodeReducer } from '@state/promo-code/promoCodeOperations';
import { paymentFormReducer } from '@state/payment/paymentFormOperations';
import { hotelReducer } from '@state/hotel/hotelOperations';
import { combinedPriceCalendarReducers } from '@state/price-calendar/combinedPriceCalendarReducers';
import { mmbReducer } from '@state/mmb';
import { agentReducer } from '@state/agent/agentOperations';
import { dealFinderReducer } from '@state/deal-finder/dealFinderOperations';
import { dealFinderResultsReducer } from '@state/deal-finder-results/dealFinderResultsOperations';
import { algoliaReducer } from '@state/algolia/algoliaOperations';
import { dealFinderLastSearchedReducer } from '@state/deal-finder-last-searched/dealFinderLastSearchedOperations';
import { mediaPostReducer, mediaPostTripTypeReducer } from '@state/agent/social-post/socialMediaPostsOperations';
import { promoBannerReducer } from '@state/promo-banner/promoBannerOperations';
import { combinedToursReducers, CombinedToursReducersType } from '@state/tours/combinedTourReducers';
import { destinationFaqReducer } from '@state/destination-faq';
import { mmbDocumentsReducer } from '@state/mmb/documents';
import { footerReducer } from '@state/content';

export const rootReducer: Reducer = combineReducers({
  navigation: navigationReducer,
  cms: cmsReducer,
  filters: filtersReducer,
  basket: basketReducer,
  extras: extrasReducer,
  extrasOptions: extrasOptionsReducer,
  seatMaps: seatMapsReducer,
  app: combinedAppReducers,
  searchResults: combinedSearchResultsReducers,
  flightsSort: flightsSortReducer,
  search: combinedSearchReducers,
  guests: guestsReducer,
  recentlyViewed: recentlyViewedReducer,
  componentsData: componentsDataReducer,
  promoCode: promoCodeReducer,
  paymentForm: paymentFormReducer,
  hotel: hotelReducer,
  priceCalendar: combinedPriceCalendarReducers,
  mmb: mmbReducer,
  agent: agentReducer,
  dealFinder: dealFinderReducer,
  algolia: algoliaReducer,
  dealFinderResults: dealFinderResultsReducer,
  dealFinderLastSearched: dealFinderLastSearchedReducer,
  mediaPost: mediaPostReducer,
  mediaPostTripTypes: mediaPostTripTypeReducer,
  promoBanner: promoBannerReducer,
  tours: combinedToursReducers,
  destinationFaq: destinationFaqReducer,
  mmbDocuments: mmbDocumentsReducer,
  footer: footerReducer
});

const reduxMigrations: MigrationManifest = {
  [1]: () => {
    // Clear recentlyViewed due to DS change
    return {
      recentlyViewed: []
    } as any;
  },
  [3]: () => {
    return {
      recentlyViewed: []
    } as any;
  }
};

const dealFinderResultsConfig = {
  key: 'results',
  storage: sessionStorage,
  blacklist: ['loading'],
  version: 0
};

const mmbConfig = {
  key: 'mmb',
  storage: sessionStorage,
  whitelist: ['token', 'bookingId'],
  version: 0
};

const appConfig = {
  key: 'packageReferences',
  storage: sessionStorage,
  whitelist: ['packageReferences', 'history', 'paymentError'],
  version: 0
};

const dealFinderConfig = {
  key: 'search',
  storage: sessionStorage,
  whitelist: ['hotelLandingSearch', 'destinationSearch'],
  version: 0
};

const toursConfig = {
  key: 'tourReferences',
  storage: sessionStorage,
  whitelist: ['tourReferences'],
  version: 0
};

const extrasConfig = {
  key: 'extras',
  storage: sessionStorage,
  version: 0
};

export const localStorageConfig = {
  key: 'local',
  storage: localStorage,
  version: 3,
  migrate: createMigrate(reduxMigrations),
  whitelist: ['recentlyViewed']
};

export const footerConfig = {
  key: 'footer',
  storage: sessionStorage,
  version: 0
};

const combinedReducers = combineReducers({
  dealFinderResults: persistReducer(dealFinderResultsConfig, dealFinderResultsReducer),
  mmb: persistReducer(mmbConfig, mmbReducer),
  navigation: navigationReducer,
  cms: cmsReducer,
  filters: filtersReducer,
  basket: basketReducer,
  app: persistReducer<CombinedAppReducersType>(appConfig, combinedAppReducers),
  extras: persistReducer(extrasConfig, extrasReducer),
  extrasOptions: extrasOptionsReducer,
  seatMaps: seatMapsReducer,
  searchResults: combinedSearchResultsReducers,
  flightsSort: flightsSortReducer,
  search: combinedSearchReducers,
  guests: guestsReducer,
  recentlyViewed: recentlyViewedReducer,
  componentsData: componentsDataReducer,
  promoCode: promoCodeReducer,
  paymentForm: paymentFormReducer,
  hotel: hotelReducer,
  priceCalendar: combinedPriceCalendarReducers,
  agent: agentReducer,
  dealFinder: persistReducer(dealFinderConfig, dealFinderReducer),
  algolia: algoliaReducer,
  dealFinderLastSearched: dealFinderLastSearchedReducer,
  mediaPost: mediaPostReducer,
  mediaPostTripTypes: mediaPostTripTypeReducer,
  promoBanner: promoBannerReducer,
  tours: persistReducer<CombinedToursReducersType>(toursConfig, combinedToursReducers),
  destinationFaq: destinationFaqReducer,
  mmbDocuments: mmbDocumentsReducer,
  footer: persistReducer(footerConfig, footerReducer)
});

export type CombinedReducersType = ReturnType<typeof combinedReducers>;

export const persistedRootReducer = persistReducer<CombinedReducersType>(localStorageConfig, combinedReducers);
