import { GeneratedByValue } from '@model/iceberg/service/contact-us';
import { PreferredContact } from '@model/forms/preferred-contact';

/* *** MODELS *** */
export interface RyanairFlightCancellationFormValues {
  name: string;
  email: string;
  bookingReference: string;
  telephone: string;
  enquiry: string;
  preferredContact: PreferredContact;
}

export interface RyanairFlightCancellationFormPayload extends GeneratedByValue {
  name: string;
  email: string;
  bookingReference: string;
  reCaptcha: string;
}

/* *** INITIAL VALUES *** */
export const ryanairFlightCancellationFormInitialValues: RyanairFlightCancellationFormValues = {
  name: '',
  email: '',
  telephone: '',
  bookingReference: '',
  enquiry: '',
  preferredContact: PreferredContact.EMAIL
};
